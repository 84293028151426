import React, { useContext, useEffect } from 'react';
import { gql } from '@apollo/client';
import { useQuery } from './Graphql';
import { useYear } from './useYear';
import { Route, Redirect } from 'react-router';
import { LoadingPage } from './LoadingPage';
import useReactRouter from 'use-react-router';
import Layout from '../Layout';
import { setTelemetryUser } from '@jbc-year-end-adj/common/telemetry';

const Context = React.createContext({
  me: null,
  client: null,
  office: null,
  clientYearly: null,
  clientYearlies: [],
  defaultYear: null,
  customerCode: null
});

export const TODOS = gql`
  fragment Todos on ClientYearly {
    syncResult
    sendWithholdingSlip
    submitPayrollReport
    submitWithholdintSlipAndLegalRecord
  }
`;

const SESSION_QUERY = gql`
  query session {
    me {
      id
      email
      name
      hasMyNumberPermission
      customerCode
      application {
        id
        name
        verified
      }
    }
    client {
      id
      clientYearlies {
        id
        year
        fixed
        fixing
      }
    }
    yearMasters {
      id
      eraCode
      eraName
      eraNumber
      earnings
      spouseEarnings
      year
    }
    defaultYear
  }
`;

const SESSION_YEARLY_QUERY = gql`
  query sessionYearly($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        year
        fixed
        fixing
        ...Todos
        clientSetting {
          id
        }
        office {
          id
          name
        }
      }
    }
  }
  ${TODOS}
`;

const SessionYearly = ({ children, me, defaultYear, clientYearlies, yearMasters }) => {
  useEffect(() => {
    setTelemetryUser({
      id: `yea-user${me.id}`,
      customer_code: me.customerCode,
      application: { id: me.application.id, name: me.application.name }
    });
  }, [me]);
  const year = useYear();
  const {
    location: { pathname }
  } = useReactRouter();
  const { loading, data } = useQuery(SESSION_YEARLY_QUERY, { variables: { year } });
  if (loading)
    return (
      <Layout>
        <LoadingPage />
      </Layout>
    );
  const {
    client,
    client: { clientYearly }
  } = data;
  if (!clientYearly) {
    return (
      <Layout>
        <div className="l-title-wrap" />
        <div className="l-wrap-xs">
          <div className="l-box-message">
            <p className="m-title-sub u-pt0 u-ta-c">年末調整が開始されていません</p>
          </div>
        </div>
      </Layout>
    );
  }
  const { office } = clientYearly;
  const isCurrentYear = clientYearly.year === defaultYear;
  if (!clientYearly.clientSetting && !pathname.match(/(\/\d+)?\/client_setting/)) {
    return <Redirect to={`/${year}/client_setting`} />;
  }
  return (
    <Context.Provider value={{ me, client, office, clientYearly, clientYearlies, defaultYear, isCurrentYear, yearMasters }}>
      {children}
    </Context.Provider>
  );
};

export const SessionProvider =
  (({ children }) => {
    const { loading, data } = useQuery(SESSION_QUERY);
    const {
      location: { pathname, search }
    } = useReactRouter();
    const year = useYear();
    if (loading)
      return (
        <Layout>
          <LoadingPage />
        </Layout>
      );
    const {
      me,
      client: { clientYearlies },
      defaultYear,
      yearMasters
    } = data;
    if (!year) {
      return <Redirect to={{ pathname: `/${defaultYear}${pathname}`, search }} />;
    }
    return <SessionYearly {...{ me, clientYearlies, defaultYear, yearMasters }}>{children}</SessionYearly>;
  })
  |> (Provider => ({ children }) => (
    <Route path="/:year(\d+)?">
      <Provider>{children}</Provider>
    </Route>
  ));

export const useSession = () => useContext(Context);
